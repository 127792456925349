import { useSession } from "next-auth/react"
import { Dispatch, SetStateAction, useEffect } from "react"

type RefreshTokenHandlerProps = {
  setInterval: Dispatch<SetStateAction<number>>
}

const RefreshTokenHandler = ({ setInterval }: RefreshTokenHandlerProps) => {
  const { data: session } = useSession()

  useEffect(() => {
    if (session && session?.expires_at) {
      // We did set the token to be ready to refresh after 10 minutes, here we set interval of 8 minutes.
      const timeRemaining = Math.round(
        (session.expires_at - 2 * 60 * 1000 - Date.now()) / 1000
      )
      setInterval(timeRemaining > 0 ? timeRemaining : 0)
    }
  }, [session, setInterval])

  return null
}

export default RefreshTokenHandler
