import { StyleFunctionProps } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  global: (props: StyleFunctionProps) => ({
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      background: mode("white", "black")(props),
      lineHeight: "base",
      transition: "none",
      // Chrome + Safari
      "&::-webkit-scrollbar": {
        width: 1,
        bgColor: "gray.100",
      },
      "&::-webkit-scrollbar-thumb": {
        bgColor: "gray.200",
      },
    },
  }),
}
