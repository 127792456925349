import type { HeadingProps } from "@chakra-ui/react"
import { Heading } from "@chakra-ui/react"

const H1 = ({ children, ...props }: HeadingProps) => {
  return (
    <Heading
      as={"h1"}
      fontSize={{ base: "3xl", md: "5xl" }}
      fontWeight={500}
      textAlign={"center"}
      {...props}
    >
      {children}
    </Heading>
  )
}

export default H1
