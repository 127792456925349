import { Berths } from "@type/directus"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"

// Define the interface for the initial data
export interface StackData {
  berth: Berths | null
}

// Define the context type
interface StackContextType {
  stackData: StackData
  updateStackData: (data: StackData) => void
}

// Create the initial context value
const initialData: StackData = {
  berth: null,
}

// Create the context
const StackContext = createContext<StackContextType>({
  stackData: initialData,
  updateStackData: () => {},
})

// Define a custom hook to access the context
export function useStackContext() {
  return useContext(StackContext)
}

// Create the StackContext provider
export function StackProvider({ children }: { children: ReactNode }) {
  const [stackData, setStackData] = useState<StackData>(initialData)

  useEffect(() => {
    // Retrieve data from local storage on page load
    const storedData = localStorage.getItem("stackData")
    if (storedData) {
      setStackData(JSON.parse(storedData))
    }
  }, [])

  const updateStackData = (data: StackData) => {
    setStackData(data)
    // Store the updated data in local storage
    localStorage.setItem("stackData", JSON.stringify(data))
  }

  return (
    <StackContext.Provider value={{ stackData, updateStackData }}>
      {children}
    </StackContext.Provider>
  )
}
