// eslint-disable-next-line import/no-anonymous-default-export
export default {
  black: "#1c1c1c",
  brand: {
    50: "#eef4f9",
    100: "#b3dcff",
    200: "#84c5fc",
    300: "#58b6e7",
    400: "#3097fa",
    500: "#207de1",
    600: "#1561af",
    700: "#0b467e",
    800: "#002a4e",
    900: "#000e1e",
  },
  stack: {
    100: "#DEDE4F",
    200: "#A4A988",
    300: "#8896B0",
    400: "#8FC5EC",
  },
}
