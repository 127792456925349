import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/700.css"
import "@theme/datepicker/Calendar.css"
import "@theme/datepicker/TimePicker.css"
import "@theme/datepicker/Clock.css"

import {
  ChakraProvider,
  HeadingProps,
  Link,
  LinkProps,
  ListItem,
  ListItemProps,
  ListProps,
  OrderedList,
  Text,
  TextProps,
  UnorderedList,
} from "@chakra-ui/react"
import H1 from "@components/Headings/H1"
import H2 from "@components/Headings/H2"
import RefreshTokenHandler from "@components/Session/RefreshTokenHandler"
import { StackProvider } from "@context/StackContext"
import { MDXProvider } from "@mdx-js/react"
import theme from "@theme/chakra/theme"
import type { AppProps } from "next/app"
import Head from "next/head"
import NextLink from "next/link"
import { SessionProvider } from "next-auth/react"
import { DefaultSeo } from "next-seo"
import { useState } from "react"

const MyApp = ({ Component, pageProps }: AppProps) => {
  const mdx_components = {
    h1: (props: HeadingProps) => <H1 {...props} />,
    h2: (props: HeadingProps) => <H2 {...props} />,
    ul: (props: ListProps) => <UnorderedList {...props} />,
    ol: (props: ListProps) => <OrderedList {...props} />,
    li: (props: ListItemProps) => (
      <ListItem
        mt={2}
        ms={4}
        {...props}
      />
    ),
    p: (props: TextProps) => (
      <Text
        as={"p"}
        marginTop={"4"}
        fontSize={"sm"}
        {...props}
      />
    ),
    a: (props: LinkProps) => (
      <Link
        as={NextLink}
        color={"brand.800"}
        textDecoration={"underline"}
        textDecorationStyle={"dotted"}
        textUnderlineOffset={"0.3em"}
        _hover={{
          textDecoration: "none",
        }}
        {...props}
      />
    ),
  }

  const [interval, setInterval] = useState<number>(0)

  return (
    <StackProvider>
      <MDXProvider components={mdx_components}>
        <ChakraProvider theme={theme}>
          <SessionProvider
            session={pageProps.session}
            refetchInterval={interval}
          >
            <Head>
              <meta
                name={"viewport"}
                content={
                  "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2,user-scalable=yes"
                }
              />
            </Head>
            <DefaultSeo
              defaultTitle={
                "Drystack App | Marsa Al Bateen Drystack | Aldar Marinas"
              }
              titleTemplate={
                "%s | Drystack App | Marsa Al Bateen Drystack | Aldar Marinas"
              }
              description={
                "The one and only Dry Stack in all of the UAE, offering complete protection of your vessel from all kinds of weather conditions and full valet service, set right in middle of the most accessible corner of Abu Dhabi city."
              }
              openGraph={{
                type: "website",
                locale: "en_AE",
                url: `${process.env.NEXT_PUBLIC_BASE_URL}`,
                siteName:
                  "Drystack App | Marsa Al Bateen Drystack | Aldar Marinas",
                images: [
                  {
                    url: `https://aldarmarinas.com/application/themes/aldar/dist/images/seo-image.jpg`,
                    width: 1200,
                    height: 630,
                    alt: "Drystack App | Marsa Al Bateen Drystack | Aldar Marinas",
                  },
                ],
              }}
            />
            <Component {...pageProps} />
            <RefreshTokenHandler setInterval={setInterval} />
          </SessionProvider>
        </ChakraProvider>
      </MDXProvider>
    </StackProvider>
  )
}

export default MyApp
